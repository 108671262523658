import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
// Components
import { Seo, EnrollmentForm, PickYourPerksAmbEnrModal } from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'

const Enrollment = () => {
  const { userType, isAuthLoading, isAuthenticated } = useAuthContext()
  const { addSelectedAmbFeeToCart } = useCartContext()
  const [isPerksModalOpen, setIsPerksModalOpen] = useState(true)
  const { prismicData }: any = usePrismic()

  const { title } = prismicData.prismicHomepage

  useEffect(() => {
    if (isAuthLoading) return
    if (userType === 'AMBASSADOR') {
      alert('It looks like you are already an ambassador')
      localStorage.removeItem('enrollmentForm')
      navigate('/')
    }
    if (!isAuthenticated && !isAuthLoading) navigate('/login')
  }, [isAuthLoading, isAuthenticated, userType])

  return (
    <>
      <Seo title={title[0].text} />
      {isPerksModalOpen && (
        <PickYourPerksAmbEnrModal
          open={isPerksModalOpen}
          onClose={() => {
            addSelectedAmbFeeToCart()
            setIsPerksModalOpen(false)
          }}
        />
      )}
      <EnrollmentForm />
    </>
  )
}

export default Enrollment
